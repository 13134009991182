.sidr {
  display: block;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 999999;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 15px;
  background: #394048;
  color: $black-c;
  border-right: 1px solid $gray-c;
}
.sidr .sidr-inner {
  padding: 45px 0;
}
.sidr .sidr-inner > p {
  margin-left: 15px;
  margin-right: 15px;
}
.sidr.right {
  left: auto;
  right: -260px;
}
.sidr.left {
  left: -100%;
  right: auto;
}

.sidr ul {
  display: block;
  margin: 0;
  padding: 0;
  border: none;
}
.sidr ul li {
  border-bottom: 1px solid #404851;
  color: #ebedf2;
  display: block;
  letter-spacing: 1px;
  line-height: 20px;
  margin: 0 10px;
}
.sidr ul li a {
  padding: 10px 15px;
  text-transform: uppercase;
  color: $white-c;
  font-size: 13px;
  font-weight: 500;
}

.sidr ul li:hover > a,
.sidr ul li:hover > span,
.sidr ul li.active > a,
.sidr ul li.active > span,
.sidr ul li.sidr-class-active > a,
.sidr ul li.sidr-class-active > span {
  // color: $color2-c;
}
.sidr ul li a,
.sidr ul li span {
  display: block;
  text-decoration: none;
  // color: $black-c;
}
.sidr ul ul li a,
.sidr ul ul li span {
  padding-left: 25px;
}
.sidr ul ul ul li a,
.sidr ul ul ul li span {
  padding-left: 35px;
}
.sidr ul li ul {
  margin: 0;
  // border-top: 1px solid $color2-c;
  border-bottom: 0;
  background: #394048;
}
.sidr ul ul > li:last-of-type {
  border-bottom: 0;
}
.sidr ul ul li:hover > a,
.sidr ul ul li:hover > span,
.sidr ul li ul li.active > a,
.sidr ul ul li.active > span,
.sidr ul li ul li.sidr-class-active > a,
.sidr ul ul li.sidr-class-active > span {
  color: $color2-c;
}

.sidr ul i {
  display: none;
}

.sidr ul li {
  position: relative;
  .submenu-trigger {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0px;
    width: 48px;
    height: 41px;
    background: none repeat scroll 0 0 rgba(255, 255, 255, 0.1);
    display: inline-block;
    padding: 0;
    z-index: 1;

    &:after {
      content: "▼";
      font-size: 18px;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%) rotate(0deg);
      color: #333;
      @include transition-all(0.4s);
    }

    &.open-submenu {
      &:after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
}

.sidr-inner ul li > div,
.sidr-inner ul li > ul {
  display: none;
}
