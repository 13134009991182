.title-parallax {
  height: 300px;
  @extend .parallax-effect;
  text-align: center;

  .text {
    width: 100%;
    position: absolute;
    @include center(xy);
  }

  h1 {
    font-size: 2rem;
    font-weight: 600;
    color: $white-c;
    text-transform: uppercase;
    text-shadow: 1px 1px 3px $black-c;
    @include media-breakpoint-down(lg) {
      font-size: 1.5rem;
    }
  }
}

.page-title-holder {
  position: relative;
  top: 20px;

  h1 {
    font-size: 2rem;
    font-weight: 600;
    text-transform: uppercase;
    @include media-breakpoint-down(lg) {
      font-size: 1.5rem;
    }
  }
}

//////////////////////////////////////////////////////////
//SIMPLE PAGE
//////////////////////////////////////////////////////////
.simple-page {
  .social-links {
    display: block;
    @extend .clearfix;
    margin-top: 20px;

    li {
      display: inline-block;
      float: left;

      &:not(:first-of-type) {
        a {
          border-left: 0;
        }
      }
    }

    a {
      display: block;
      line-height: 48px;
      width: 50px;
      border: 1px solid $border-c;
      color: #888;
      text-align: center;
      font-size: 1.25rem;

      &:hover {
        border-color: $color1-c;
        background-color: $color1-c;
        color: $white-c;
      }
    }
  }
}

.article-sidebar {
  padding-top: 60px;
}

.blog-single {
  &-info {
    border: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
  }

  &-title {
    color: #3f444a;
    font-size: 30px;
    transition: 0.3s all ease-in-out;
    padding: 60px 0 20px;

    &:hover {
      color: $color2-c;
    }
    &::after {
      display: none;
    }
  }
}

//////////////////////////////////////////////////////////
//CONTACT PAGE
//////////////////////////////////////////////////////////
.contact-page {
  &-top {
    z-index: 1 !important;

    @include media-breakpoint-down(md) {
      margin: 30px 0 !important;
    }

    &-title {
      font-size: 30px !important;
      color: #3f444a !important;
      font-weight: 700 !important;
      line-height: 1.1 !important;
      text-transform: uppercase;
    }
  }

  &-map-holder {
    margin-top: -15px;
    position: absolute;
    width: 100%;
    z-index: 0;

    @include media-breakpoint-down(md) {
      position: relative;
    }

    #map {
      height: 615px;
    }
  }

  padding-top: 30px;

  background: url("https://www.knjigovodstveneusluge.rs/assets/base/img/content/backgrounds/bg_feedback_1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  h1 {
    color: #3f444a;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    padding: 15px 0 5px;
  }

  .line-left {
    position: relative;
    display: block;
    text-align: center;
    width: 15px;
    left: -7.5px;
    height: 3px;
    background-color: $white-c;

    margin: 0 auto 30px auto;
  }

  .line-right {
    position: relative;
    display: block;
    text-align: center;
    width: 15px;
    right: -7.5px;
    top: -33px;
    height: 3px;
    margin: 0 auto 30px auto;
    background-color: #32c5d2;
  }

  h2 {
    font-size: 1.25rem;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  .paralax-line {
    margin: 0 0 30px 0;
  }

  &-blog {
    background-color: white !important;
    padding: 30px;
    margin: 0 30px 60px 0;

    @include media-breakpoint-down(sm) {
      margin: 0 0 60px 0;
    }

    &-title {
      font-size: 30px;
      font-weight: 600;
      text-transform: uppercase;
    }
    &-text {
      font-weight: 300;
      margin-top: 10px;
      margin-bottom: 50px;
      font-size: 17px;
    }

    a {
      padding: 10px 20px 8px 20px;
      font-weight: 600;
      text-transform: uppercase;
      border: 2px solid #32c5d2;
      color: #32c5d2;
      background: none;
    }
  }

  .form-box {
    padding-left: 50px;

    @include media-breakpoint-down(sm) {
      background-color: $white-c;
      padding: 30px;
    }
    h2 {
      margin-bottom: 30px;
      font-size: 30px;
      color: #3f444a;
      font-weight: 600;

      position: relative;
    }

    p {
      font-size: 17px;
      font-weight: 300;
      a {
        text-decoration: underline;

        &:hover {
          color: $color2-c;
        }
      }
    }

    input,
    textarea {
      background: #f5f5f5;
      padding: 10px;
      border: none;
      margin-bottom: 20px;
      color: #555;
      font-weight: 300;
      border: 1px solid #d0d7de;
    }
    textarea {
      min-height: 150px;
    }

    input::placeholder,
    textarea::placeholder {
      color: #999;
    }
    button {
      height: 40px;
      width: auto;
      background-color: #ffffff;
      border-radius: 0;
      border: 1px solid $color1-c;
      font-size: 14px;
      font-weight: bold;
      color: $color1-c;
      float: right;
      cursor: pointer;
      position: relative;
      padding: 0 20px;
      i {
        color: $color1-c;
        margin-right: 10px;
      }
      &:hover {
        border-color: $color2-c;
        @include transition-all(0.4s);
      }
      &:hover,
      &:hover i {
        color: $color2-c;
        @include transition-all(0.4s);
      }
      @include transition-all(0.4s);
    }
    input.chaptcha_code {
      text-align: center;
    }
  }

  .text-box {
    .text-editor {
      //background: $color1-c;
      background: #ddd;
      padding: 22px 22px 7px 22px;
      color: #777;

      //p, h3, a{color: #ffffff;}

      table {
        border: 0;
        font-size: 0.9375rem;
        margin-top: 22px;

        tbody {
          border-bottom: 1px solid $border-c;
        }

        td {
          border: 0;
          border-top: 1px solid #999;
          line-height: 26px;
          padding: 20px 0 20px 45px;
          position: relative;

          &:before {
            content: "";
            font-family: "FontAwesome";
            position: absolute;
            left: 5px;
            @include center(y);
            font-size: 1.25rem;
            width: 30px;
            display: inline-block;
            text-align: center;
            color: $color2-c;
          }
        }

        tr:nth-of-type(1) td:before {
          content: "\f095";
        }
        tr:nth-of-type(2) td:before {
          content: "\f017";
        }
        tr:nth-of-type(3) td:before {
          content: "\f041";
        }
        tr:nth-of-type(4) td:before {
          content: "\f19c";
        }
      }
    }
  }

  #contact_form_error {
    color: #e7505a;
    text-align: right;
    padding-right: 15px;
    position: absolute;
    bottom: 10px;
    right: 0px;
    p {
      font-weight: 600;
    }
  }
  #success {
    background: #fff;
    color: $color2-c;
    font-size: 1.15rem;
    font-weight: bold;
    padding: 0 15px;
    position: absolute;
    right: 0;
    top: -2px;
  }

  @include media-breakpoint-down(md) {
    .form-box {
      .col-md-12 {
        overflow: hidden;
      }
    }
  }

  @include media-breakpoint-only(xs) {
    .contact-data {
      table {
        td {
          padding: 15px 0 15px 40px;

          &:before {
            left: 5px;
          }
        }
      }
    }
  }
}

.contact-text {
  p {
    padding-top: 30px;
  }
}

#contact_form_error {
  color: #e7505a;
  font-weight: 600;
  font-size: 18px;
  padding-right: 15px;
}

//////////////////////////////////////////////////////////
//FAQ PAGE
//////////////////////////////////////////////////////////
.faq-page {
  .faq-accordion {
    clear: both;

    .ui-accordion-header-icon {
      display: none;
    }
  }
}

.faq-accordion {
  .accordion-block {
    > h3 {
      margin: 0;
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 26px;
      padding: 12px 45px 12px 0;
      position: relative;
      cursor: pointer;
      border-bottom: 1px solid $border-c;

      > span {
        position: absolute;
        right: 0;
        top: 10px;
        height: 30px;
        width: 30px;
        line-height: 30px;
        display: inline-block;
        text-align: center;
        color: $text-c;
        font-size: 1.5rem;
        @include transition-all(0.4s);
      }
    }

    &.active > h3 {
      border-bottom: none;
      color: $color1-c;

      > span {
        color: $text-c;
        @include transform(rotate(180deg));
      }
    }

    > div {
      padding: 15px 0;
    }
  }
}

/* SEARCH PAGE */
.search-page {
  ul {
    @include media-breakpoint-down(sm) {
      margin-top: 60px !important;
    }
  }
  .serach-form {
    position: absolute;
    right: 20px;
    top: 0px;
    display: flex;
    width: 300px;

    @include media-breakpoint-down(sm) {
      top: -50px;
    }

    input[type="text"] {
      width: 100%;
      padding-right: 38px;
      font-style: italic;
      color: #999999;

      &:focus {
        font-style: normal;
        color: $color1-c;
      }
    }

    input[type="submit"] {
      width: 38px;
      height: 38px;
      background-color: transparent;
      border: none;
      text-indent: -9999px;
      padding: 0;
      display: inline-block;
      position: absolute;
      right: 0;
      bottom: 0;
      cursor: pointer;
      z-index: 1;
    }

    &:after {
      font-family: FontAwesome;
      content: "\f002";
      position: absolute;
      right: 0;
      bottom: 0;
      color: #fff;
      font-size: 22px;
      background: $color1-c;
      padding: 4px 9px 5px;
    }
  }
}
.error-page {
  padding: 70px 0 70px;
  .text-editor {
    text-align: center;
    h1 {
      font-size: 2rem;
    }
    h4 {
      line-height: 1.6;
    }
  }
  a {
    color: $color1-c;
    &.btn-tr {
      margin-top: 20px;
      &:hover {
        padding-right: 18px;
      }
    }
  }
}

.comment-captcha {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  align-items: center;
}

.comment-button {
  cursor: pointer;
  border: 1px solid #32c5d3;
  background-color: #32c5d3;
  color: #fff;
}

.comment-leave {
  font-size: 30px;
  color: #3f444a;
  font-weight: 500;
  margin: 0 0 10px;
  text-transform: uppercase;
  font-weight: 600 !important;

  .paralax-line {
    margin: 0 0 30px 0;
  }
}

.title-line {
  font-size: 28px;
  font-weight: 600;
  color: #3f444a;
  position: relative;
  margin-bottom: 40px;
  &::before {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 30px;
    height: 3px;
    background-color: #32c5d2;
  }
}

.c-hr {
  &::before {
    border-top: 1px solid $color2-c;
    display: block;
    height: 1px;
    content: " ";
    width: 35%;
    position: absolute;
    left: 4%;
    top: 2.2em;
  }

  &::after {
    border-top: 1px solid $color2-c;
    display: block;
    height: 1px;
    content: " ";
    width: 35%;
    position: absolute;
    right: 4%;
    top: 2.2em;
  }
}
