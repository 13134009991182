// body {
//   overflow-y: hidden !important;
// }
#header {
  position: relative;
  z-index: 2;
  border-bottom: 1px solid #2f353b;

  section.top {
    position: relative;
    // z-index: 99;
    min-height: 122px;
    align-items: center;
    display: flex;

    .header-container {
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    .header-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;

      @include media-breakpoint-down(md) {
        flex-direction: column;
      }

      &-right {
        @include media-breakpoint-down(md) {
          display: flex;
          justify-content: right;
          width: 100%;
        }
      }
    }

    .contact {
      display: inline-block;
      > div {
        display: inline-block;
        border-right: 1px solid $color2-c;
        padding-right: 20px;
        a {
          color: $white-c;

          &:hover {
            opacity: 0.7;
          }
        }
        &:first-of-type {
          border-left: 1px solid $color2-c;
        }
        &:hover {
          opacity: 0.7;
        }
      }
      .icon {
        display: inline-block;
        width: 40px;
        height: 38px;
        vertical-align: middle;
        text-align: center;
        line-height: 34px;
      }
      .phone .icon {
        font-size: 19px;
      }
      .darker {
        color: #cbcbcb;
      }

      @include media-breakpoint-only(sm) {
        > div {
          display: block;
          line-height: 14px;

          .icon {
            // display: none;
          }
        }

        .email {
          display: none;
        }
      }

      @include media-breakpoint-only(xs) {
        .email {
          display: none;
        }
      }
    }

    .lang_social {
      float: right;

      > .languages {
        float: right;
        padding: 7px 7px 5px 7px;
        height: 28px;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;

        &-choose {
        }

        .c-drop-trigger {
          // line-height: 38px;
        }

        .active {
          background-color: #5e9cd1;
        }

        a {
          background-color: #ddd;
          letter-spacing: 1px;
          font-size: 12px;
          font-weight: 500;
          text-transform: uppercase;
          padding: 8px 8px;

          span:first-of-type {
            padding-right: 7px;
          }
        }

        .c-drop-content {
          width: auto !important;
          min-width: 155px;
        }

        .content-holder {
          // padding: 0 20px;
          top: 45px;
          text-align: left;
          // border: 3px solid $color1-c;
          background-color: $white-c;
        }

        li {
          a {
            font-weight: 600;
            font-size: 0.875rem;
            text-transform: none;
            color: $black-c;
            position: relative;
            display: block;
            text-align: left;
          }

          &:hover a {
            color: $color2-c;
          }
        }
      }
      .social {
        float: right;
        font-size: 0;
        margin-top: 8px;

        ul {
          display: flex;

          li > a {
            color: #aeb8c2;
            font-weight: 500;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            height: 15px;

            @include media-breakpoint-only(lg) {
              font-size: 13px;
            }
          }
        }

        a {
          color: #aeb8c2;
          border-right: 1px solid #dfe4e8;
          padding: 0 10px;
          font-size: 15px;
          display: inline-block;

          &:hover {
            color: $color2-c;
          }
        }
      }
      @include media-breakpoint-down(md) {
        .social {
          display: none;
        }
      }
    }
  }

  section.main {
    background-color: #2f353b;
    font-size: 0;
    position: relative;
    min-height: 70px;
    max-height: 70px;

    @include media-breakpoint-down(md) {
      width: 100%;
      position: absolute;
      top: 0;
      z-index: -1;
      min-height: 122px !important;
      // max-height: 122px !important;
      background-color: $white-c;
    }

    .logo {
      float: left;
      margin: 30px 0 0;
    }

    .riht_main {
      float: right;
      // padding: 10px 0;
      width: 100%;

      .content-holder {
        // border: 1px solid $color1-c;
        padding: 9px;
        background-color: #2f353b;

        @include media-breakpoint-down(md) {
          background-color: $white-c;
        }
      }

      form {
        font-size: 0.975rem;
        // border-bottom: 1px solid #ccc;
        position: relative;

        &:after {
          font-family: FontAwesome;
          content: "\f00d";
          position: absolute;
          right: 5px;
          top: 5px;
          color: #828b96;
          font-size: 22px;

          &:hover {
            color: $color2-c;
          }
        }

        input.inputbox {
          background: none;
          border: none;
          font-style: italic;
          color: #999;
          padding: 12px 30px 12px 2px;
          margin: 0;
          display: inline-block;
          font-size: 22px;

          &:focus {
            font-style: normal;
          }
        }

        input.button_search {
          width: 30px;
          height: 30px;
          background-color: transparent;
          border: none;
          text-indent: -9999px;
          padding: 0;
          display: inline-block;
          position: absolute;
          right: 0;
          bottom: 10px;
          cursor: pointer;
          z-index: 1;
        }
      }

      #search {
        float: right;
        padding: 11px 0;

        a.searchBtn {
          position: relative;

          span {
            font-size: 16px;
            width: 35px;
            height: 35px;
            line-height: 35px;
            text-align: center;
            // background: $color1-c;
            color: $white-c;

            &:hover {
              color: $color2-c;
            }
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      .logo {
        height: 40px;

        p {
          height: 100%;
        }

        img {
          max-height: 100%;
          width: auto;
        }
      }

      .riht_main {
        padding: 0;

        .navigation {
          padding: 14px 15px;

          @include media-breakpoint-down(md) {
            padding: 7px 15px 0;
          }
        }
      }
    }

    @include media-breakpoint-only(xs) {
      .logo {
        flex-basis: 100%;
        margin-bottom: 15px;
      }
    }
  }
}

.search {
  @include media-breakpoint-down(md) {
    margin-right: 25px !important;
    color: #aeb8c2;

    .fa-search {
      &:hover {
        color: #748596 !important;
      }
    }
  }
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.search-desktop {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.search-responsive {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

//default style
.c-drop-holder {
  position: relative;
  .c-drop-content {
    position: absolute;
    right: 0;
    top: 110%;
    width: 360px;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    @include transition(opacity 0.4s, top 0.4s);
  }
  .c-drop-trigger {
    @extend .noselect;
    cursor: pointer;
  }

  &.drop-open {
    .c-drop-content {
      top: 100%;
      opacity: 1;
      visibility: visible;
      z-index: 1;
    }
  }

  @include media-breakpoint-down(xs) {
    position: static;
    .c-drop-content {
      width: 100% !important;
    }
  }
}

@include media-breakpoint-up(lg) {
  .social-responsive {
    display: none;
  }
}

@include media-breakpoint-down(md) {
  .search-holder {
    height: 120px;

    form {
      height: 100%;

      input {
        height: 100%;
      }
    }
  }

  .button_search_close {
    right: -2px !important;
    bottom: 62px !important;
  }
}

.c-drop-holder {
  .c-drop-content {
  }
  .c-drop-trigger {
    font-size: 0.875rem;
  }
}
.hide-search {
  display: none !important;
}
.testzindex {
  z-index: 11;
}

@include media-breakpoint-down(sm) {
  .logo {
    p {
      a {
        img {
          height: 60px !important;
          width: 200px !important;
        }
      }
    }
  }
}
