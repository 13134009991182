/* Products pages - catalogue pages */

.products-page {
  padding-top: 55px;

  /* All products page */
  .category {
    margin-bottom: 20px;

    img {
      width: 100%;
      margin-bottom: 15px;
    }

    .title-pr {
      font-weight: 700;
      font-size: 1.225rem;
      display: block;
      text-align: center;
    }
  }

  /* Products list - category */
  .product-box {
    text-align: center;
    margin-bottom: 30px;

    .product-item {
      border-bottom: 1px solid $color1-c;
      position: relative;
      padding-bottom: 20px;

      &::before {
        content: "";
        position: absolute;
        bottom: -1px;
        left: 50%;
        -moz-transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        width: 0;
        height: 1px;
        background-color: $color2-c;
        -webkit-transition: all 0.4s ease-in-out;
        -moz-transition: all 0.4s ease-in-out;
        -ms-transition: all 0.4s ease-in-out;
        -o-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
      }

      &:hover::before {
        width: 100%;
      }
    }

    &:nth-child(3n + 1) {
      clear: both;
    }

    &:hover {
      .photo {
        border: 1px solid $color2-c;
      }

      .name {
        color: $color2-c;
      }
    }

    .photo {
      border: 1px solid $color1-c;
      display: block;
      position: relative;
      margin-bottom: 15px;
      @include transition-all(0.4s);

      .brand {
        position: absolute;
        bottom: 10px;
        right: 10px;
        display: block;
        width: 50px;
      }
    }

    .name-id {
      line-height: 1.4;

      .name {
        display: block;
        padding-bottom: 7px;
        font-size: 1.225rem;
        font-weight: 700;
        @include transition-all(0.4s);
      }
      .id {
        display: block;
        padding-bottom: 7px;

        span {
          font-weight: 700;
        }
      }
    }

    .price {
      display: block;
      span {
        color: $color2-c;
        span {
          font-size: 1.225rem;
          font-weight: 700;
        }
      }
    }
  }
}

/* price page */
.price-page {
  .back-cat {
    padding: 15px 0 30px;

    .back-link {
      float: right;
    }
  }

  .block-title {
    display: block;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  a {
    color: $color2-c;
  }

  .box {
    padding-bottom: 15px;
    padding-left: 7px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    z-index: 0 !important;
  }

  .id {
    padding-left: 7px;
    span {
      font-weight: 600;
    }
  }

  .brend {
    .pan {
      font-weight: 600;
    }
  }
  .price-button {
    width: fit-content;
    padding: 4px 8px;
    border-radius: 4px;
    border-color: #3f444a;
    color: #3f444a;
    cursor: pointer;
    margin-top: 10px;

    &:hover {
      color: $color2-c;
    }
  }
  .price {
    //padding-top: 15px;

    span {
      font-size: 1.225rem;
      font-weight: 700;
      color: $color2-c;
    }
  }

  .check,
  .box {
    z-index: 0 !important;
  }
}

#submit-aap_form {
  width: fit-content;
}
