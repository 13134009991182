.c-slider {
  position: relative;
}

.c-slider-arr {
  position: absolute;
  display: inline-block;
  text-align: center;
  top: 50%;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  @include translate(0, -50%);
}

//////////////////////////////
//HOMEPAGE SLIDER
//////////////////////////////

#slider_homepage {
  // height: 270px;
  // overflow: clip;

  .btn-tr {
    display: none;
  }
}
.homepage-slider {
  height: 270px !important;
  overflow: clip;

  .slick-list {
    width: 100%;
  }

  &-label {
    z-index: 1 !important;
    transition: 0.2s all ease-in-out;
    color: #fff !important;

    &:hover {
      color: #eee !important;
    }
  }

  .c-slider-arr {
    color: $color2-c !important;
    border-color: $color2-c !important;
    line-height: 30px;
    width: 60px;
    height: 60px;
    font-size: 45px;
    opacity: 0.5;
    transition: 0.3s all ease-in-out;

    span {
      position: relative;
      top: 3px;
      left: 2px;
    }
    span {
      color: $color2-c;
      opacity: 0.5;
      transition: 0.3s all ease-in-out;
    }
    &.prev-slide {
      left: 40px;
      border: 2px solid;
    }
    &.next-slide {
      right: 40px;
      border: 2px solid;
    }
    &:hover,
    span:hover {
      color: $color2-c !important;
      border-color: $color2-c !important;
      opacity: 1;
    }
    &:hover span {
      color: $color2-c !important;
      border-color: $color2-c !important;
      opacity: 1;

      @include transition-all(0.4s);
    }
  }

  .slick-slide {
    position: relative;
    overflow: hidden;
  }

  .text-block-holder {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;

    > .container {
      position: relative;
      height: 100%;
    }
  }

  .text-block {
    text-align: left;
    padding: 0;
    display: inline-block;
    position: absolute;
    @include center(y);
    top: 40%;
    left: 15px;
    opacity: 0;
    width: 57%;
    @include transition(opacity 2s 0.5s);
  }

  .text1 {
    font-size: 2.25rem;
    font-weight: 900;
    color: $white-c;
    line-height: 1.2;
    text-transform: uppercase;
    @include transition(opacity 2s 1s, left 2s);
  }

  .text2 {
    font-size: 2.25rem;
    font-weight: 600;
    line-height: 1.2;
    color: $white-c;
    padding-bottom: 20px;
    @include transition(opacity 2s 1s, left 2s);
  }

  .text3 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
    color: $white-c;
    padding-bottom: 28px;
    @include transition(opacity 2s 1s, left 2s);
  }

  .slick-active {
    .text-block {
      opacity: 1;
    }

    .text1,
    .text2,
    .text3 {
      opacity: 1;
      left: 0;
      z-index: 1;
    }

    .link-block a {
      opacity: 1;
      top: 0;
      z-index: 1;
    }
  }
}

.slick-dots {
  position: absolute;
  // right: 30px;
  // top: 30px;
  // width: 130px;
  text-align: center;
  bottom: 15px;
  width: 100%;

  li {
    display: inline-block;
    margin: 0;

    button {
      border: none;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin: 5px 7px;
      text-indent: -999999px;
      background: #e1e1e1;

      &:hover {
        background-color: $color2-c;
      }
    }

    &.slick-active {
      button {
        background-color: $color2-c;
      }
    }
  }
}

.homepage-slider {
  height: 450px;

  .slick-slide {
    height: 450px;
  }
}

@include media-breakpoint-down(md) {
  .homepage-slider {
    .text-block {
      top: 50%;
      width: 80%;
    }
    .c-slider-arr.prev-slide {
      left: 5px;
    }
    .c-slider-arr.next-slide {
      right: 5px;
    }
  }
}

@include media-breakpoint-between(md, lg) {
  .homepage-slider {
    .text-block {
      padding: 20px 10px;
    }

    .text1 {
      font-size: 12px;
    }

    .text2 {
      font-size: 36px;
    }
  }

  .homepage-slider {
    height: 400px;

    .slick-slide {
      height: 400px;
    }
  }
}

@include media-breakpoint-only(sm) {
  .homepage-slider {
    .text-block {
      padding: 15px 10px;
    }

    .text1 {
      font-size: 12px;
    }

    .text2 {
      font-size: 24px;
    }
  }

  .homepage-slider {
    height: 300px;

    .slick-slide {
      height: 300px;
    }
  }
}

@include media-breakpoint-only(xs) {
  .homepage-slider {
    .c-slider-arr {
      display: none !important;
    }

    .text-block {
      padding: 15px 10px;
      left: 50%;
      @include translate(-50%, -50%);
    }

    .text1 {
      font-size: 11px;
      margin-top: 5px;
    }

    .text2 {
      font-size: 18px;
    }

    .link-block a {
      line-height: 15px;
      padding: 3px 10px;
      font-size: 10px;
    }
  }

  .homepage-slider {
    height: 250px;

    .slick-slide {
      height: 250px;

      @include media-breakpoint-only(xs) {
        height: 270px;
      }
    }
  }
}

//////////////////////////////
// LATEST NEWS HOME SLIDER
//////////////////////////////

.comment-slider {
  .slick-dots {
    > li:nth-last-child(-n + 2) {
      display: none;

      @include media-breakpoint-down(xs) {
        display: initial;
      }
    }

    @include media-breakpoint-down(sm) {
      > li:nth-last-child(-n + 2) {
        display: initial;
      }

      > li:nth-last-child(-n + 1) {
        display: none;
      }
    }
  }
}

//customer slider
.customers-slider {
  .slick-dots {
    > li {
      &:nth-child(n + 3) {
        display: none;

        @include media-breakpoint-down(xs) {
          display: initial;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .slick-dots {
      > li {
        &:nth-child(n + 3) {
          display: initial;
        }
      }

      // > li {
      //   &:nth-child(-n + 2) {
      //     display: none;
      //   }
      // }
    }
  }

  .slick-slide {
    > img {
      margin: auto;
    }
  }
}
