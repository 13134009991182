footer {
  background-color: #2e3336;
  color: $black-c;

  .footer {
    &-menu {
      display: flex;

      > ul {
        &:not(:first-child) {
          margin-left: 50px;
        }

        > li {
          list-style-type: none;
          line-height: 30px;

          > a {
            color: #b1c4d0;
            font-size: 16px;
            font-weight: 300;

            &:hover {
              color: $color2-c !important;
            }
          }
        }
      }

      @include media-breakpoint-down(xs) {
        flex-direction: column;

        > ul {
          &:not(:first-child) {
            margin-left: 0;
          }
        }
      }
    }

    &-newsletter {
      margin-top: 30px;

      &-title {
        color: #d5dfe5;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 20px;
      }

      &-text {
        color: #7e8b93;
        font-size: 16px;
      }
    }

    &-line {
      width: 100%;
      height: 1px;
      background-color: #393f42;
      margin: 40px auto;
    }
  }

  .footer-c {
    // border-top: 3px solid $color1-c;
    padding-top: 70px;
  }

  img {
    margin-bottom: 15px;
  }

  .foo-block {
    padding-top: 20px;
    padding-bottom: 20px;
    border-right: 1px solid #ddd;
    padding-left: 20px;
    display: table-cell;
    float: none;
    height: 260px;
    width: 50%;
    vertical-align: top;

    &.contact {
      border-right: none;
    }

    .f_about_text {
      margin-bottom: 15px;
    }

    .title-f {
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 700;
      margin-top: 10px;
      margin-bottom: 30px;
    }

    ul {
      li {
        display: block;
        margin-bottom: 10px;
      }
      a {
        display: inline-block;
        position: relative;
        color: $black-c;

        &:hover {
          color: $color2-c;
        }
      }
    }

    table {
      margin-top: -10px;
    }
    tbody > tr {
      td {
        width: 300px;
        padding-left: 28px;
        position: relative;
        font-size: 0.875rem;
        padding-top: 7px;
        padding-bottom: 7px;
        &:before {
          position: absolute;
          left: 0px;
          top: 50%;
          @include translate(0, -50%);
          font-size: 1rem;
          font-family: FontAwesome;
        }
      }

      &:nth-of-type(1) td:before {
        content: "\f041";
      }
      &:nth-of-type(2) td:before {
        content: "\f095";
      }
      &:nth-of-type(3) td:before {
        content: "\f0e0";
      }
      &:nth-of-type(4) td:before {
        content: "\f017";
      }
    }

    .social {
      border-top: 1px solid #dddddd;
      margin: 10px 0 0;
      padding-top: 10px;

      a {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        background: $color1-c;
        color: #fff;
        line-height: 30px;
        text-align: center;
        display: inline-block;

        &:hover {
          background: $color2-c;
          @include transition-all(0.4s);
        }
      }
    }
  }

  .copyright {
    display: block;
    color: $black-c;
    font-size: 20px;
    font-weight: 300;
    padding: 20px 0 20px;

    .client {
    }
    .power {
      text-align: right;
    }

    .fa {
      color: $color1-c;
    }
    a {
      color: #3f444a;
      font-weight: 400;
      transition: 0.4s all ease-in-out;

      &:hover {
        color: $color2-c;
      }
    }

    @include media-breakpoint-down(md) {
      .client,
      .power {
        text-align: center;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  footer .foo-block {
    width: 49%;
    display: inline-block;

    &:nth-child(2) {
      border-right: none;
    }
  }
}

@media (max-width: 360px) {
  footer .foo-block {
    width: 100%;
    height: auto;
    border-right: none;
    border-bottom: 1px solid #eeeeee;
  }
}

#to-top {
  display: inline-block;
  background-color: transparent;
  width: 40px;
  height: 40px;
  text-align: center;
  position: fixed;
  bottom: 20px;
  right: 10px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  border: 2px solid #89939e;
  border-radius: 50%;
  &:after {
    content: "\f062";
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    font-size: 20px;
    line-height: 30px;
    color: #89939e;
  }
  &:hover {
    cursor: pointer;
    background-color: $gray-c;
    &:after {
      color: $blue;
    }
  }
  &:active {
    background-color: $gray-c;
    &:after {
      color: $blue;
    }
  }
  &.show {
    opacity: 0.5;
    visibility: visible;
  }
  @include media-breakpoint-down(md) {
    bottom: 20px;
    right: 10px;
  }
}
.show {
  opacity: 0.5;
  visibility: visible;
}

.c-postfooter {
  background-color: #31383c;
  padding: 30px 0;
}

// .body-locked {
//   overflow-y: hidden !important;
// }
