// ***********************************
// BREADCRUMBS
// ***********************************
.breadcrumbs {
  line-height: 1;
  @extend .clearfix;
}
.breadcrumbs ul {
  margin: 0;
  padding: 0;
  list-style: none;
  float: right;
}
.breadcrumbs ul li {
  float: none;
  display: inline-block;
  color: #2f353b;
  font-weight: 400;
  position: relative;
  font-size: 1rem;
}
.breadcrumbs ul li:not(:first-child) {
  margin-left: 8px;
}
.breadcrumbs ul li:not(:last-child) {
  margin-right: 8px;
}
.breadcrumbs ul li:after {
  font-family: FontAwesome;
  color: #2f353b;
  content: "/";
  position: absolute;
  right: -2px;
  top: 50%;
  @include center(y);
  color: #2f353b;
  font-weight: 600;
  font-size: 20px;
}
.breadcrumbs ul li:last-of-type:after {
  display: none;
}
.breadcrumbs ul li:first-child {
  padding-left: 0;
}
.breadcrumbs ul li a {
  text-decoration: none;
  position: relative;
  font-weight: normal;
}
.breadcrumbs ul li a {
  padding-right: 15px;
  cursor: pointer;
  color: #2f353b;
}

.breadcrumbs ul li:last-of-type a {
  padding-right: 0;
}
.breadcrumbs ul li a:hover {
  color: $color2-c;
}
.breadcrumbs ul li.current,
.breadcrumbs ul li.current a {
  cursor: default;
  text-decoration: none;
  pointer-events: none;
  color: $red-c;
  text-transform: uppercase;
  font-weight: 600;
}

.breadcrumbs-spage {
  padding: 80px 0;
  background: url("https://www.knjigovodstveneusluge.rs/assets/base/img/content/backgrounds/bg-9.jpg");
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 40px;
}

.breadcrumbs-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;

  p {
    padding: 0;
  }
}

.breadcrumbs-article {
  li {
    &:last-child {
      display: none;
    }

    &:nth-last-child(2) {
      &::after {
        content: "";
      }
    }
  }
}
