input,
select,
textarea {
  font-size: 100%; /* Corrects font size not being inherited in all browsers */
  margin: 0; /* Addresses margins set differently in IE6/7, F3/4, S5, Chrome */
  vertical-align: baseline; /* Improves appearance and consistency in all browsers */
}

textarea {
  overflow: auto; /* Removes default vertical scrollbar in IE6/7/8/9 */
  padding-left: 3px;
  vertical-align: top; /* Improves readability and alignment in all browsers */
}

label {
  padding-right: 10px;
}

input,
textarea {
  border: 1px solid $border-c;
  line-height: 20px;
  padding: 10px 16px;
  width: 100%;
  min-width: 1px;
}

select {
  border: 1px solid $border-c;
  line-height: 20px;
  padding: 9px 15px;
  width: 100%;
  min-width: 1px;
}

input[type="color"] {
  border: none;
  background: none;
  padding: 0;
}

input[type="checkbox"],
input[type="radio"] {
  padding: 0; /* Addresses excess padding in IE8/9 */
}
input[type="search"] {
  -webkit-appearance: textfield; /* Addresses appearance set to searchfield in S5, Chrome */
  -webkit-box-sizing: content-box; /* Addresses box sizing set to border-box in S5, Chrome (include -moz to future-proof) */
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-decoration {
  /* Corrects inner padding displayed oddly in S5, Chrome on OSX */
  -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  /* Corrects inner padding and border displayed oddly in FF3/4 www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/ */
  border: 0;
  padding: 0;
}

//INPUT AND TEXTAREA
.c-input,
.c-textarea {
  border-radius: 0;
  height: 40px;
  margin-top: 20px;
  @include placeholder {
    color: $text-c;
  }
  &.no-margin {
    margin-top: 0;
  }
}
.c-textarea {
  min-height: 200px;
  resize: vertical;
}

//LABEL
label {
  font-weight: 400;

  &.c-input-label {
    line-height: 20px;
    padding: 10px 0;
    margin-top: 20px;
    margin-bottom: 0;
  }

  &.mandatory {
    position: relative;

    &:after {
      position: absolute;
      top: 3px;
      right: -12px;
      font-family: FontAwesome;
      content: "\f069";
      font-size: 12px;
      color: $red-c;
    }
  }

  @include media-breakpoint-down(sm) {
    &.c-input-label {
      padding: 0px;
      margin-top: 0px;
      top: 15px;
      position: relative;
    }

    &.mandatory:after {
      top: -5px;
    }
  }
}

.control-label {
  text-align: right;
  font-weight: 300;
  font-size: 18px;
  color: #2e353b;
  @include media-breakpoint-only(md) {
    text-align: left;
  }

  @include media-breakpoint-only(sm) {
    text-align: left;
  }
}

.mandatory {
  padding-left: 5px;
  font-size: 10px;
  position: absolute;
  bottom: 15px;
  right: 3px;
  color: #e7505a;
}

.form-group {
  height: 46px;
  margin-bottom: 20px;

  @include media-breakpoint-down(sm) {
    height: fit-content;
  }
}

.form-button {
  width: fit-content;
  margin-bottom: 10px;
  display: block;
  border: 1px solid #3f444a;
  border-radius: 4px;
  columns: #3f444a;
  cursor: pointer;

  input {
    transition: 0.3s all ease-in-out !important;
  }

  &:hover {
    input {
      color: $color2-c;
    }
  }
  input {
    padding: 2px 4px;
    font-size: 16px;
    font-weight: 300;
  }
}

.form-title {
  font-size: 22px;
  color: #3f444a;
  margin-bottom: 40px;
}

.form-text {
  font-weight: 300;
  font-size: 17px;
}

.form-border {
  &-title {
    font-size: 22px;
    color: $color2-c;
  }
  &-el {
    position: relative;
    height: 1px;
    background-color: $color2-c;
    margin: 20px 0 40px;

    &::after {
      border-radius: 0 !important;
      left: 0;
      display: inline-block;
      position: absolute;
      content: "";
      width: 10px;
      height: 10px;
      top: -4px;
      margin-left: -5px;
      background-color: #32c5d2 !important;
    }
  }
}

.form-radio-inline,
.c-checkbox-inline {
  display: flex;
  justify-content: left;
}
// .c-checkbox {
//   width: 15%;
// }
.form-radio-element {
  width: fit-content;
}

//BUTTONS
.btn-t1 {
  display: inline-block;
  text-align: center;
  box-shadow: none;
  border-radius: 0;
  line-height: 20px;
  padding: 8px 15px;
  background-color: $color2-c;
  color: $white-c;
  border: 2px solid $color2-c;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    color: $white-c;
  }

  &.ghost {
    background-color: transparent;
    color: $color2-c;
    &:hover {
      color: $color2-c;
    }
  }

  &.red {
    background-color: $color1-c;
    border-color: $color1-c;
    &:hover {
      color: $white-c !important;
    }
    &.ghost {
      background-color: transparent;
      color: $color1-c;
      border-color: $color1-c;
      &:hover {
        color: $red-c !important;
      }
    }
  }

  &.margin {
    margin-top: 20px;
  }
}

.btn-more {
  display: inline-block;
  text-align: center;
  box-shadow: none;
  border-radius: 0;
  line-height: 20px;
  padding: 3px 15px;
  border: 2px solid $border-c;
  text-transform: uppercase;
  font-size: 11px;
  @include transition-all(0.4s);
  &:hover {
    background-color: $color1-c;
    border-color: $color1-c;
    color: $white-c;
  }
}

//RADIO BUTTONS
.radio-box {
  input {
    visibility: hidden;
    position: absolute;
  }

  label {
    @include no-select();
    position: relative;
    line-height: 22px;
    padding: 10px 0 10px 35px;
    margin-top: 20px;
    font-weight: 400;
    cursor: pointer;

    &:before {
      position: absolute;
      content: "";
      width: 22px;
      height: 22px;
      border: 1px solid #ccc;
      background-color: #eee;
      border-radius: 50%;
      left: 0;
      top: 10px;
    }

    &:after {
      position: absolute;
      content: "";
      width: 12px;
      height: 12px;
      background-color: $red-c; /*ovo je backup color*/
      border-radius: 50%;
      left: 5px;
      top: 15px;
      display: none;
    }

    &.active:after {
      display: inline-block;
    }

    &.radio-left {
      padding-left: 30px;
    }
  }
}

//CHECKBOXES
.checkbox {
  input {
    position: absolute;
    visibility: hidden;
  }

  label {
    @include no-select();
    position: relative;
    line-height: 22px;
    padding: 10px 35px 0 0;
    display: block;
    text-align: right;
    cursor: pointer;
    font-weight: 400;
    &:before {
      content: "";
      position: absolute;
      right: 0;
      top: 11px;
      width: 22px;
      height: 22px;
      border: 1px solid $border-c;
    }

    a {
      color: $red-c !important;
      font-style: italic;
      &:hover {
        color: $red-c !important;
        text-decoration: underline !important;
      }
    }
    &:after {
      content: "\f00c";
      font-family: FontAwesome;
      position: absolute;
      right: 4px;
      top: 12px;
      display: none;
      color: $black-c;
    }

    &.active:after {
      display: inline-block;
    }
  }

  &.left {
    label {
      padding: 10px 0 0 35px;
      &:before {
        right: auto;
        left: 0;
      }
      &:after {
        right: auto;
        left: 3px;
      }
    }
  }

  &.margin {
    margin-top: 20px;
  }
}

//SELECTBOX
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  border-radius: 0;

  height: 40px;
  @include placeholder {
    color: $text-c;
  }
}

.select {
  display: block;
  position: relative;

  .fa {
    position: absolute;
    right: 15px;
    top: 50%;
    @include translate(0, -50%);
    color: $red-c;
  }
}

//NOTIFICATIONS
.notification {
  position: relative;
  @extend .clearfix;

  &.right {
    text-align: right;
  }

  span {
    margin-top: 10px;
    display: block;
    font-weight: 600;
    color: $red-c;
    position: relative;
    top: 5px;
  }

  &.updated-cart,
  &.removed-from-wishlist,
  &.user-account-updated {
    position: absolute;

    span {
      margin-top: 0;
      top: -30px;
    }
  }
}
