// ***********************************
// PAGINATION
// ***********************************

/* Pagination */
.pagination {
  @extend .clearfix;
}

ul.pagination-list {
  text-align: center;
  padding: 30px 0;
  //  @media(max-width: 767px){
  //    text-align: center;
  //  }
}
ul.pagination-list li {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 5px;
  vertical-align: top;
}
ul.pagination-list li a {
  border: 1px solid $gray-c;
  border-radius: 50%;
  color: inherit;
  cursor: pointer;
  display: block;
  line-height: 24px;
  padding-top: 7px;
  position: relative;
  text-align: center;
  height: 40px;
  width: 40px;
}
ul.pagination-list li a.disabled {
  cursor: default;
  pointer-events: none;
}
ul.pagination-list li a:hover {
  color: inherit;
  text-decoration: none;
  background-color: $color2-c;
  color: $white-c;
}
ul.pagination-list li a.current {
  background-color: $color2-c;
  cursor: default;
  pointer-events: none;
  color: $white-c;
}
ul.pagination-list li:last-of-type {
  margin: 0;
}
@media (max-width: 580px) {
  ul.pagination-list {
    width: 100%;
    text-align: center;
  }
}
