// ***********************************
// VARIABLES
// ***********************************
/* open-sans-300 - latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/open_sans/open-sans-v27-latin-ext-300.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/open_sans/open-sans-v27-latin-ext-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/open_sans/open-sans-v27-latin-ext-300.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/open_sans/open-sans-v27-latin-ext-300.woff") format("woff"),
    /* Modern Browsers */
      url("../fonts/open_sans/open-sans-v27-latin-ext-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/open_sans/open-sans-v27-latin-ext-300.svg#OpenSans")
      format("svg"); /* Legacy iOS */
}

/* open-sans-regular - latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/open_sans/open-sans-v27-latin-ext-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/open_sans/open-sans-v27-latin-ext-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../fonts/open_sans/open-sans-v27-latin-ext-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/open_sans/open-sans-v27-latin-ext-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("../fonts/open_sans/open-sans-v27-latin-ext-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/open_sans/open-sans-v27-latin-ext-regular.svg#OpenSans")
      format("svg"); /* Legacy iOS */
}

/* open-sans-600 - latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/open_sans/open-sans-v27-latin-ext-600.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/open_sans/open-sans-v27-latin-ext-600.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/open_sans/open-sans-v27-latin-ext-600.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/open_sans/open-sans-v27-latin-ext-600.woff") format("woff"),
    /* Modern Browsers */
      url("../fonts/open_sans/open-sans-v27-latin-ext-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/open_sans/open-sans-v27-latin-ext-600.svg#OpenSans")
      format("svg"); /* Legacy iOS */
}

/* open-sans-700 - latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/open_sans/open-sans-v27-latin-ext-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/open_sans/open-sans-v27-latin-ext-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/open_sans/open-sans-v27-latin-ext-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../fonts/open_sans/open-sans-v27-latin-ext-700.woff") format("woff"),
    /* Modern Browsers */
      url("../fonts/open_sans/open-sans-v27-latin-ext-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/open_sans/open-sans-v27-latin-ext-700.svg#OpenSans")
      format("svg"); /* Legacy iOS */
}

// TYPOGRAPHY
$font-family1: "Roboto Condensed", sans-serif;

//
// Headings
//

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1,
.h1 {
  font-size: $font-size-h1;
}
h2,
.h2 {
  font-size: $font-size-h2;
}
h3,
.h3 {
  font-size: $font-size-h3;
}
h4,
.h4 {
  font-size: $font-size-h4;
}
h5,
.h5 {
  font-size: $font-size-h5;
}
h6,
.h6 {
  font-size: $font-size-h6;
}

// COLORS
$white-c: #fff;
$black-c: #555;
$gray-c: #ddd;
$red-c: #e82c2c;
$green-c: #127486;
$green-c1: #32c5d2;
$blue: #004d8d;
$brown: #545454;
$brown-2: #bda09c;

$text-c: $black-c;
$rectangle-c: $black-c;
$border-c: $gray-c;

$color1-c: $green-c;
$color2-c: $green-c1;

$alert-color: $red-c;
$success-color-c: $green-c;

$block-margin-bottom: 40px;
