/* Home page custom css */

/* HOME BLOCK 1 */
.h-block-1 {
  .norme {
    font-size: 0;
    text-align: center;

    img {
      display: inline-block;
      width: 370px;
    }
  }
}

/* HOME ABOUT US */
.h_about_us {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: calc(50% - 220px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .title-p2 {
    color: $color1-c;
    text-transform: none;

    &:after {
      background: #ccc;
    }
  }

  .text-editor {
    padding-bottom: 25px;
  }
}

/* HOME BLOCK 2 */
.h_block_2 {
  background: #eeeeee;
  padding: 40px 0;
  margin-bottom: 80px;

  .col-md-4 {
    display: flex;

    .icon {
      display: inline-block;
      width: 90px;
    }

    .text-block {
      display: inline-block;
      width: 72%;

      .title-b2 {
        color: $color1-c;
        font-weight: bold;
        padding-bottom: 25px;
      }

      p {
        padding-bottom: 20px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .h_about_us::before {
    background-image: none !important;
  }
  .h_block_2 {
    .col-md-4 {
      padding-bottom: 40px;

      .icon {
        width: 70px;
      }
    }
  }
}

/* HOME BLOCK 3 */
.h_block_3 {
  margin-bottom: 80px;

  .text-block {
    h2 {
      font-weight: bold;
      color: $color1-c;
      font-size: 2.5rem;
    }

    h3 {
      color: $color1-c;
      font-weight: bold;
      font-size: 1.25;
    }

    ul {
      padding-left: 15px;
      margin-left: 7px;

      li {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          top: 7px;
          left: -15px;
          width: 5px;
          height: 5px;
          background-color: $color2-c;
          border-radius: 50%;
        }
      }
    }
  }

  .slick-dots {
    width: auto;
    text-align: center;
    padding: 20px 0;

    li {
      display: inline-block;
      font-size: 0;

      button {
        border: 1px solid #555;
        border-radius: 50%;
        background: none;
        width: 15px;
        height: 16px;
        margin: 0 5px;

        &:hover {
          background: #555;
        }
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  .h_block_3 {
    .text-block {
      td {
        width: 100% !important;
        height: auto;
        display: block;
      }
    }
  }
}

.c-icon {
  height: 100px;
}

.paralax {
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  background-color: transparent;
  min-height: 80%;
  padding: 80px 0;

  &-top {
    color: $white-c;
    text-align: center;
  }

  &-title {
    font-size: 30px;
    color: $white-c;
    font-weight: 600;
    text-transform: uppercase;
  }
  &-subtitle {
    text-transform: uppercase;

    font-size: 15px;
    font-weight: 400;
    margin: 40px 0 30px;
  }

  &-line {
    width: 30px;
    height: 3px;
    background-color: $color2-c;
    margin: 0 auto 30px auto;
  }

  &-bottom {
    text-align: center;

    @include media-breakpoint-down(sm) {
      margin-bottom: 40px;
    }

    &-title {
      color: $white-c;
      font-size: 20px;
      font-weight: 600;
      text-transform: uppercase;
    }

    &-text {
      color: $white-c;
      font-size: 17px;
      padding: 0px 60px;
      margin: 20px 0 30px 0;
      font-weight: 300;
    }
  }
  &-button {
    border: 2px solid $color2-c;
    color: $color2-c;
    font-size: 16px;
    padding: 10px 20px 8px;

    &:hover {
      background-color: $color2-c;
      color: $white-c;
    }
  }
}

.comment {
  background-color: #f7f7f7 !important;
  padding: 60px 0 180px 0;
  text-align: center;

  .slick-dots {
    bottom: -100px;
  }

  &-title {
    font-size: 30px;
    color: #3f444a;
    margin: 0 0 10px;
    text-transform: uppercase;
    font-weight: 600 !important;
  }

  &-subtitle {
    font-size: 17px;
    font-weight: 400;
    color: #5c6873;
    margin: 30px 0;
  }

  &-slider {
    &-image {
      float: left;
      margin: 0 10px 0 0;
      border-radius: 50%;
      width: 70px;
    }
  }

  &-content {
    padding: 0 30px;
  }

  &-text {
    padding: 30px;
    position: relative;
    background-color: $white-c;
    color: #5c6873;

    &:before {
      content: " ";
      position: absolute;
      top: 100%;
      left: 40px;
      border: solid transparent;
      border-width: 17px;
      border-top-color: #fff;
      pointer-events: none;
    }

    > p {
      font-size: 17px;
      font-weight: 300;
      color: #5c6873;
    }
  }

  &-person {
    position: relative;
    padding: 25px 0 0 25px;
    text-transform: uppercase;

    &-holder {
      text-align: left;
    }

    &-name {
      font-size: 16px;
      font-weight: 500;
    }

    &-position {
      margin-top: 3px;
      color: $color2-c;
      font-size: 17px;
      font-weight: 600;
    }
  }
}

.customers {
  padding: 60px 0 180px;
  &-top {
    text-align: center;
  }

  .slick-dots {
    bottom: -100px;
  }
}

.info {
  padding: 80px 0;
  background-color: #f7f7f7 !important;

  &-text {
    p {
      background-color: #ffffff;
      font-size: 15px;
      margin-bottom: 7px;
      padding: 25px;
      position: relative;
      width: 40%;
      z-index: 1;

      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }
  }

  &-button {
    background: #32c5d2;
    border-color: #32c5d2;
    color: #ffffff !important;
    font-size: 16px;
    font-weight: 700;
    padding: 11px 20px 9px 20px;
    text-transform: uppercase;
    text-decoration: none !important;
    transition: 0.3s all ease-in-out;

    &:hover {
      color: #ffffff !important;
    }
  }

  &-slider {
    background-color: $color2-c;
    // height: 394px;
    // width: 458px;
    padding: 130px 60px;
    text-align: center;

    &-sidebar {
      // width: 263px;
      // height: 300px;
      padding: 40px 20px;

      margin-top: 20px;

      &-about {
        margin-top: 2px;
        padding: 120px 20px;
      }
    }

    .banner-link {
      > p {
        color: $white-c;
        font-size: 28px;
        text-transform: uppercase;
      }
      .slider-action {
        font-size: 32px;
        font-weight: 600;
      }
    }

    .slick-dots {
      text-align: right;
      width: 85%;
      li.slick-active > button {
        background-color: $white-c;
        opacity: 1;
      }
      li > button {
        opacity: 0.5;

        &:hover {
          background-color: $white-c;
          opacity: 1;
        }
      }
    }
  }

  &-content {
    height: 389px;
    @include media-breakpoint-down(lg) {
      height: 674px;
    }

    .paralax-line {
      margin: 0 0 30px 0;
    }

    &-title {
      color: #2f353b !important;
      font-size: 30px;
      font-weight: 600;
      margin-top: 25px;
    }

    &-text {
      background-color: $white-c;
      padding: 25px;
      width: 40%;
      position: relative;
      top: -10px;
      z-index: 1;
      @include media-breakpoint-down(lg) {
        width: 100%;
      }

      > p {
        font-size: 15px;
        font-weight: 300;
      }
    }
  }
  &-button {
    color: $white-c;
    background: $color2-c;
    border-color: $color2-c;
    font-size: 16px;

    padding: 10px 20px 9px 20px;

    &:hover {
      background-color: #27a8b4;
      border-color: #27a8b4;
    }
  }

  &-image {
    position: absolute;
    top: 0;
    right: 65px;
    width: 420px;

    @include media-breakpoint-down(lg) {
      position: static;
      margin: 20px 0;
    }
  }
}

/* Define the keyframes for the fade-up animation */
@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(20px); /* Move the element 20px down */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply the fade-up animation to elements */
.fade-up {
  animation: fadeUp;
  animation-delay: 0.5s;
  animation-duration: 1s;
}
@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-down {
  animation: fadeDown;
  animation-delay: 0.5s;
  animation-duration: 1s;
}
@keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-left {
  animation: fadeLeft;
  animation-delay: 0.5s;
  animation-duration: 1s;
}
@keyframes fadeLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-in {
  animation: fadeIn;
  animation-delay: 0.5s;
  animation-duration: 1s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
