/* Text Below Slider */

.text-below-slider {
  background: #2f353b;
  padding: 20px 0;
  color: $white-c;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.2;
  // margin-bottom: 40px;

  &-text {
    font-size: 28px;
    color: $white-c;
    font-weight: 400;

    &:hover {
      color: $color2-c;
    }
  }
}

@include media-breakpoint-down(sm) {
  .btn-tr {
    margin-top: 15px;
  }
}
