.navigation {
  // float: right;
  // padding: 10px 0;
  // width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  nav > ul {
    margin: 0;

    li {
      list-style: none;
      position: relative;
    }
    .active > a,
    .onPath > a {
      // color: $color1-c;
      padding: 25px 10px 22px 10px;
      letter-spacing: normal;
      font-size: 16px;
      color: #fff;
    }

    .active,
    .onPath {
      > .submenu-trigger:after {
        color: $color2-c;
        @include transition-all(0.4s);
      }
    }

    a {
      display: block;
    }

    > li {
      display: inline-block;
      float: left;
      // margin-right: 15px;
      font-size: 1rem;
      position: relative;

      // .submenu-trigger:after {
      //   content: "\f078";
      //   font-family: FontAwesome;
      //   font-size: 9px;
      //   position: relative;
      //   top: -1px;
      //   display: inline-block;
      //   margin: 0 0 0 -9px;
      //   font-weight: 700;
      // }

      &:hover {
        > .submenu-trigger:after {
          color: $color1-c;
          @include transition-all(0.4s);
        }
      }

      @include media-breakpoint-only(lg) {
        margin-right: 5px;
      }

      > a {
        padding: 25px 10px 22px 10px;
        letter-spacing: normal;
        font-size: 16px;
        color: #fff;

        > span {
          color: $color2-c;
        }
      }

      // SUBMENU
      > ul {
        position: absolute;
        left: 0;
        top: 110%;
        opacity: 0;
        visibility: hidden;
        z-index: -1;
        /*white-space: nowrap;*/
        min-width: 160px;
        @include transition(opacity 0.4s, top 0.4s);

        > li {
          padding-right: 2px;

          .submenu-trigger:after {
            content: "\f054";
            position: absolute;
            right: 7px;
            top: 16px;
            font-weight: normal;
          }
          &:hover,
          &.active {
            > .submenu-trigger:after {
              color: $color1-c;
              @include transition-all(0.4s);
            }
          }
        }
      }
      &:hover {
        > a {
          color: $color1-c;
        }
        > ul {
          top: 100%;
          opacity: 1;
          visibility: visible;
          z-index: 1;
          background-color: #fff;
          padding: 0;
          border-top: 1px solid #dddddd;
          border-left: 1px solid #dddddd;
          border-right: 1px solid #dddddd;

          &:before {
            content: "";
            font-size: 22px;
            line-height: 10px;
            color: #dddddd;
            display: inline-block;
            border-width: 0 8px 8px 8px;
            border-color: transparent transparent #fff transparent;
            border-style: solid;
            position: absolute;
            bottom: 100%;
            left: 20px;
          }

          li {
            border-bottom: 1px solid #dddddd;
          }

          a {
            //color: $white-c;
            padding: 10px 15px;

            &:hover {
              color: $color1-c;
            }
          }
        }
      }
    }

    > li li {
      position: relative;
      white-space: nowrap;
      > ul {
        background-color: #fff;
        padding: 0;
        position: absolute;
        left: 100%;
        top: 0;
        margin-left: 10px;
        opacity: 0;
        visibility: hidden;
        z-index: -1;
        min-width: 160px;
        border-top: 1px solid #dddddd;
        border-left: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
        @include transition(opacity 0.4s, margin-left 0.4s);

        &:before {
          content: "";
          position: absolute;
          top: 14px;
          left: -7px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 8px 7px 8px 0;
          border-color: transparent #ffffff transparent transparent;
        }

        &:after {
          content: "";
          display: inline-block;
          position: absolute;
          left: -16px;
          top: 0;
          width: 16px;
          height: 100%;
        }
      }

      &:hover {
        > ul {
          margin-left: 10px;
          opacity: 1;
          visibility: visible;
          z-index: 1;
        }
      }
    }

    > li > li {
      ul {
        opacity: 0;
        visibility: hidden;
      }
      &:hover ul {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .sub-menu {
    display: none;
  }
}

#responsive-menu-button {
  // z-index: 999999999999999999999;
  float: right;
  background: #f8f9fa;
  padding: 5px 7px;

  display: none;
  width: 30px;
  height: 30px;
  position: relative;
  @include transform(rotate(0deg));
  @include transition-all(0.25s);
  cursor: pointer;

  span {
    // display: block;
    // position: absolute;
    // height: 2px;
    // width: 15px;
    // border-radius: 9px;
    // opacity: 1;
    // left: 0;
    background: #9fadba;
    @include transform(rotate(0deg));
    @include transition-all(0.25s);

    width: 15px;
    display: block;
    height: 2px;
    padding: 0px;
    margin: 3px 0;
  }

  span:nth-child(1) {
    top: 0px;
  }

  span:nth-child(2),
  span:nth-child(3) {
    top: 3px;
  }

  span:nth-child(4) {
    top: 6px;
  }
}

.sidr-animating,
.sidr-open {
  #responsive-menu-button {
    span:nth-child(1) {
      left: 50%;
      top: 13px;
      width: 0%;
    }

    span:nth-child(2) {
      top: 13px;
      @include transform(rotate(45deg));
    }

    span:nth-child(3) {
      top: 13px;
      @include transform(rotate(-45deg));
    }

    span:nth-child(4) {
      left: 50%;
      top: 13px;
      width: 0%;
    }
  }
}

#navigation {
  display: inline-block;

  ul > li > a {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;

    @include media-breakpoint-only(lg) {
      font-size: 12px;
    }
  }
}

.fixed-header {
  padding-top: 67px;

  .top {
    width: 100%;
    left: 0;
    top: 0;
    -webkit-animation-duration: 600ms;
    animation-duration: 600ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;

    @include media-breakpoint-down(md) {
      z-index: 10;
      position: fixed !important;
      box-shadow: 0px 3px 9px -4px rgba(0, 0, 0, 0.25);
      background-color: $white-c;
    }
  }

  .main {
    position: fixed !important;
    width: 100%;
    left: 0;
    top: 0;
    -webkit-animation-duration: 600ms;
    animation-duration: 600ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    z-index: 1;
    background-color: #2f353b;

    border-top: 1px solid #2f353b;
    border-bottom: 1px solid #2f353b;

    @include media-breakpoint-down(md) {
      box-shadow: 0 3px 9px -4px rgba(0, 0, 0, 0.25);
      background-color: $white-c !important;
      border-top: 1px solid $white-c;
      border-bottom: 1px solid $white-c;
    }
  }

  @include media-breakpoint-down(md) {
    padding-top: 52px;
    .navigation {
      // background: $red-c;
    }
  }
}

@include media-breakpoint-up(md) {
  .fixed-header {
    .navigation {
      #responsive-menu-button {
        display: none;
      }

      #navigation {
        display: table;
        margin: 0 auto;
        padding: 0 5px;
      }
    }
  }
}
@include media-breakpoint-up(xl) {
  .fixed-header {
    .navigation {
      #navigation {
        width: 1140px;
      }
    }
  }
}
@include media-breakpoint-down(lg) {
  .fixed-header {
    .navigation {
      #navigation {
        width: 960px;
      }
    }
  }
}
@include media-breakpoint-only(md) {
  .fixed-header {
    .navigation {
      #navigation {
        width: 720px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  #responsive-menu-button {
    display: block;
    float: right;
  }
  #navigation {
    display: none !important;
  }
}

/* Right column menu navigation */
.sidebar-menu {
  ul {
    li {
      position: relative;

      a {
        padding: 10px 0 10px 20px;
        display: block;
        font-weight: bold;
        border-bottom: 1px solid #cccccc;

        &:before {
          content: "\f105";
          font-family: FontAwesome;
          display: inline-block;
          position: absolute;
          top: 10px;
          left: 10px;
          color: $color2-c;
        }
      }

      a:hover,
      &.active > a {
        color: $color2-c;
      }

      ul {
        padding-left: 10px;
        //display: none;

        li {
          a {
            border-bottom: 1px solid #dddddd;
            font-weight: normal;
          }
        }
      }

      //&.onPath ul{display: block;}

      &:last-child a {
        //border-bottom: none;
      }
    }
  }
}

.btn {
  background: #32c5d2;
  border-color: #32c5d2;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 20px 9px 20px;
}

#sidr-main {
  height: 100vh;
}

.nav-related-post-title {
  display: inherit !important;
  width: 100% !important;
}
