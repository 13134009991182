.newsletter-section {
  padding: 0 0 45px 0;
  position: relative;

  #form_newsletter {
    // padding: 20px;
    // width: 760px;
    // background: $color1-c;
    color: #fff;
    margin: 0 auto;
    position: relative;

    .text-input {
      // width: 50%;
    }

    .gcaptcha-submit {
      // position: absolute;
      // right: 0;
      // top: 0;
      // width: 50%;
      padding: 20px 0;
      box-sizing: border-box;
      height: 100%;

      .g-recaptcha {
        width: 50%;
        transform: scale(0.55);
        -webkit-transform: scale(0.55);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
        display: inline-block;
        float: left;
      }
      .submit-box {
        width: 50%;
        float: right;
        box-sizing: border-box;
        background-color: $color2-c;
        font-size: 20px;
        transition: 0.2s all ease-in-out;

        &:hover {
          background-color: #777;
        }
      }
    }

    .submit_box {
      /*width: 92px;*/
      text-align: center;
    }

    input[type="text"] {
      margin-top: 2px;
      color: #fff;
      background: none transparent;
      padding: 10px 15px;
    }

    input[type="submit"] {
      background: none;
      border: none;
      color: #fff;
      cursor: pointer;
      padding: 10px 15px;
    }
  }

  #newsleter_form_error {
    position: absolute;
    bottom: 20px;
    text-align: center;
    width: 100%;
    padding: 0 20px;
    font-weight: bold;
    left: 0;
  }
}

@include media-breakpoint-down(lg) {
  .gcaptcha-submit {
    display: flex;
    flex-direction: column;
  }
}

@include media-breakpoint-down(md) {
  .newsletter-section {
    padding: 0 00px 45px;
    display: inline-block;

    #form_newsletter {
      width: 230px;
    }
  }
}
@include media-breakpoint-down(sm) {
  .newsletter-section {
    width: 100%;

    #form_newsletter {
      width: 100%;

      .text-input {
        width: 100%;
      }
      .gcaptcha-submit {
        width: 100%;
        position: static;
        padding: 20px 0;
        margin-bottom: 24px;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .newsletter-section #form_newsletter input[type="submit"] {
    z-index: 1;
    background: $color2-c;
    position: relative;
  }
}
