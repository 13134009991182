/* News - Home Latest News, List News & Article */

/* Home List News*/
.latest-news {
  margin-bottom: /*$block-margin-bottom*/ 80px;
}

.c-content-media-1-slider {
  .slick-dots {
    top: 30px !important;
    right: 30px !important;
    width: 130px !important;
  }
}

.news-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.news {
  border: 1px solid #dddddd;
  margin-bottom: 40px;

  &-type {
    background-color: #32c5d2;
    color: #fff;
    margin: 20px 0 0 20px;
    width: fit-content;
    text-align: center;
    padding: 0 5px;
  }

  // .slick-dots {
  //   top: -15px;
  //   left: 40%;
  //   height: 20px;
  // }

  .text_block {
    padding: 20px;

    .date_author_comments {
      display: inline-block;
      width: 100%;
      border-bottom: 1px solid #dddddd;
      color: #777;
      padding-bottom: 7px;
      margin-bottom: 10px;

      > div {
        display: inline-block;
      }

      span {
        // padding-right: 7px;
      }

      .date {
        font-size: 14px;
      }
      .author {
      }
    }

    h2 {
      font-weight: bold;
      font-size: 19px;
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    h3 {
      font-weight: bold;
      font-size: 30px;
      margin-bottom: 12px;
      text-transform: uppercase;
    }

    .short_text {
      font-size: 17px;
      font-weight: 300;
      height: 68px;
      display: table-cell;
      vertical-align: middle;
      width: 100%;

      .comments {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 10px;

        a {
          color: #3f444a;
          font-size: 15px;
          font-weight: 400;
        }

        ul {
          display: flex;
          flex-wrap: wrap;

          li {
            &:not(:last-child) {
              margin-right: 5px;
            }
            a {
              color: #ffffff !important;
              font-size: 12px;
              list-style: none;
              padding: 0 5px;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  &:hover {
    img {
      opacity: 0.8;
      @include transition-all(0.4s);
    }

    h2 a,
    h3 a {
      color: $color2-c;
      @include transition-all(0.4s);
    }
  }
}

/* News - Article */
.article {
  .date_comments_location_author {
    margin-bottom: 20px;
  }
  .short-text {
    font-size: $font-size-h3;
    color: $color1-c;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .text-editor {
    padding-top: 20px;
  }

  .tag-list {
    border-bottom: 1px dotted #999;
    padding: 15px 0;
    margin-bottom: 20px;

    span {
      font-weight: bold;
    }

    span,
    .tags,
    li {
      display: inline-block;
    }

    li a {
      color: $color1-c;
    }

    li a:hover {
      color: $color2-c;
    }
  }
}

.comment {
  &-author {
    color: #3f444a;
    font-weight: 600;
    &:hover {
      color: #32c5d2;
    }
  }

  &-timestamp {
    color: #3f444a;
    font-weight: 500;
  }
  &-reply {
    margin: 15px;
  }
}

#answer {
  span {
    color: $color2-c;
  }
}
.comments-icon {
  font-size: 14px !important;
  padding-right: 3px !important;
}
