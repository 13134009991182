/* Services Baner - Home & Page Services List */

.services {
  margin-bottom: 40px;

  &-head {
    color: #3f444a;
    font-size: 30px;
    font-weight: 600;
    margin: 30px 0 25px 0;
    width: 95%;

    &:hover {
      color: $color2-c;
    }
  }

  &-text {
    color: #5c6873;
    font-size: 18px;
    font-weight: 300;
  }

  .box:nth-child(3n + 1) {
    clear: both;
  }

  .text-block {
    padding: 0 10px;

    .title-s {
      color: $brown;
      font-size: 15px;
      padding-bottom: 60px;

      &:after {
        bottom: 30px;
        transform: scaleX(1);
        transition: transform 0.7s;
      }

      a {
        transition: 0.7s ease-in-out;
      }
    }
  }

  p {
    font-size: 0.9375rem;
    color: #777;
    text-align: center;
  }

  .service {
    border: 1px solid #dddddd;
    background: #f5f5f5;
    padding-bottom: 25px;
    margin-bottom: 30px;

    > a {
      padding: 0 0 30px;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      width: 100%;
      color: $brown;
    }

    &:hover a {
      color: $color2-c;
      //@include transition-all(.7s);
    }

    &:hover .title-s:after {
      transform: scaleX(1.5);
    }
  }

  .service:hover h3 {
    color: $color2-c;
    @include transition-all(0.4s);
  }
}

.services-page {
  .services {
    margin: 0;
    @extend .clearfix;
  }

  .text-editor {
    margin-bottom: 30px;
    padding: 0 30px;
    text-align: center;
  }
}
